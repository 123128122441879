<template>
  <div class="forms">
    <vee-form @submit="createKssTask">
      <div class="time-wrapper">
        <div class="time-box">
          <div class="left-side" v-if="step === 1">
            <div class="area" id="div_calendar-date">
              <div class="icon-and-text">
                <font-awesome-icon icon="fa-calendar-day" class="kss-icon"/>
                <p class="kss-text">{{ $t('KssCreatorComponent.selectDate') }}</p>
              </div>
              <kss-calendar-component/>
            </div>
          </div>
          <div class="spacer" v-if="step === 1"></div>
          <div class="right-side" v-if="step === 1">
            <div class="area" id="div_calendar-time">
              <div class="icon-and-text">
                <font-awesome-icon icon="fa-clock" class="kss-icon"/>
                <p class="kss-text">{{ $t('KssCreatorComponent.selectTime') }}</p>
              </div>
              <div class="runtime" id="div_runtime">
                <kss-runtime-component/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="full-width" v-if="step === 2">
        <div class="icon-and-text">
          <font-awesome-icon :icon="['fas', 'users-viewfinder']" class="users-icon"/>
          <p class="info-text">{{ $t('KssCreatorComponent.furtherInfo') }}</p>
        </div>
        <div class="label-container">
          <label class="calendar-label" id="lbl_calDate">
            <font-awesome-icon icon="fa-calendar" class="calendar-icon"/>
            {{ getFormattedDate ?? $t('KssRuntimeComponent.selectDate') }}
          </label>
          <label class="clock-label" id="lbl_cal-time">
            <font-awesome-icon icon="fa-clock" class="clock-icon"/>
            {{ getFormattedTime ?? $t('KssRuntimeComponent.selectRuntime') }}
          </label>
        </div>
        <kss-form-component/>
      </div>
      <div class="btns">
        <button
          v-if="step === 1"
          type="button"
          class="btn-create"
          name="next"
          id="btn_next"
          @click="step = 2"
        >
          {{ $t('KssCreatorComponent.nextButton') }}
        </button>
        <button
          v-if="step === 2"
          type="button"
          class="btn-create"
          name="back"
          id="btn_back"
          @click="step = 1"
        >
          {{ $t('KssCreatorComponent.backButton') }}
        </button>
        <button
          v-if="step === 2"
          type="button"
          class="btn-create"
          name="create"
          id="btn_createKSS"
          @click.prevent="createKssTask"
        >
          {{ $t('KssCreatorComponent.createKssTracker') }}
        </button>
      </div>
    </vee-form>
  </div>
</template>

<script>
import KssFormComponent from '@/components/Home/KssCreator/KssFormComponent.vue';
import KssRuntimeComponent from '@/components/Home/KssCreator/KssRuntimeComponent.vue';
import KssCalendarComponent from '@/components/Home/KssCreator/KssCalendarComponent.vue';

export default {
  name: 'KssCreatorComponent',
  props: {
    data: Object,
  },
  components: {
    KssFormComponent,
    KssRuntimeComponent,
    KssCalendarComponent,
  },
  data() {
    return {
      step: 1,
    };
  },
  watch: {
    checked() {
      this.$emit('input', this.displayArchivedTasks);
    },
  },
  computed: {
    createdTasks() {
      return this.$store.getters.getCurrentCreatedTasks;
    },
    allKssParticipants() {
      return this.$store.getters.getKssParticipants;
    },
    involvedKssUsers() {
      return this.$store.getters.getInvolvedKssUsers;
    },
    archivedTasks() {
      return this.$store.getters.getCurrentArchivedTasks;
    },
    getFormattedDate() {
      const date = this.$store.getters.getSelectedDate;
      if (!date) {
        return null;
      }
      return `${date.getDate()}.${(date.getMonth() + 1)}.${date.getFullYear()}`;
    },
    getFormattedTime() {
      if (!this.$store.state.kss.selectedHours && this.$store.state.kss.selectedHours !== 0) {
        return null;
      }
      return `${(`0${this.$store.state.kss.selectedHours}`).slice(-2)}:${(`0${this.$store.state.kss.selectedMinutes}`).slice(-2)}`;
    },
    showCalendar: {
      get() {
        return true;
        // return this.$store.state.kss.showCalendar;
      },
      set(value) {
        this.$store.commit('setShowCalendar', value);
      },
    },
    showRuntime: {
      get() {
        return this.$store.state.kss.showRuntime;
      },
      set(value) {
        this.$store.commit('setShowRuntime', value);
      },
    },
    filterCreatedTasks() {
      return this.createdTasks.filter((el) => el.name.toLowerCase()
        .includes(this.searchbar.toLowerCase())
      || el.description.toLowerCase().includes(this.searchbar.toLowerCase())).toSorted();
    },
    filterArchivedTasks() {
      return this.archivedTasks.filter((el) => el.name.toLowerCase()
        .includes(this.searchbar.toLowerCase())
      || el.description.toLowerCase().includes(this.searchbar.toLowerCase())).toSorted();
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    async createKssTask() {
      try {
        if (this.checkForErrors()) {
          return;
        }

        const data = {
          involvedUsers: this.$store.state.kss.kssParticipants.filter((e) => e.isChecked)
            .map((e) => e._id),
          name: this.$store.state.kss.newMeetingSubject,
          description: this.$store.state.kss.newDescription,
        };

        const dateTaskBegin = new Date(
          this.$store.state.kss.selectedDate.getFullYear(),
          this.$store.state.kss.selectedDate.getMonth(),
          this.$store.state.kss.selectedDate.getDate(),
          this.$store.state.kss.selectedHours,
          this.$store.state.kss.selectedMinutes,
        );

        if (this.$store.state.kss.isRecurringTask) {
          data.repeatRules = this.getRecurringObject(dateTaskBegin.toUTCString());
        } else {
          data.date_taskBegin = dateTaskBegin.toUTCString();
          data.repeatRules = [];
        }

        data.involvedExternalUsers = this.$store.state.kss.inviteEmails;

        await this.$store.dispatch('createKssTask', data);
        if (!this.$store.state.kss.hasSimilarTasks) {
          this.$store.commit('setShowCalendar', true);
          this.$store.commit('setShowRuntime', true);
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('ToastComponenet.kss.kssCreated'),
            type: 'success',
          });
        } else {
          this.$store.commit('addFrontendOverlay', {
            id: Math.random(),
            type: 'similarKssTasks',
            postData: data,
          });
        }
        this.closePopup();
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    checkForErrors() {
      let hasError = false;
      if (!this.$store.state.kss.selectedDate) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.$store.state.kss.selectedHours && this.$store.state.kss.selectedHours !== 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectTimeErr'),
          type: 'error',
        });
        hasError = true;
      }

      if ((this.$store.state.kss.selectedHours || this.$store.state.kss.selectedHours === 0)
        && this.$store.state.kss.selectedDate
        && !this.checkDateAndTimeValid()
        && !this.$store.state.kss.isRecurringTask) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.pastDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.$store.state.kss.newMeetingSubject) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectSubjectErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.$store.state.kss.kssParticipants.filter((e) => e.isChecked).length === 0
        && this.$store.state.kss.inviteEmails.length === 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponenet.kss.selectParticipantsErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.$store.state.kss.isRecurringTask) {
        const type = this.$store.state.kss.recurringType;
        const subType = this.$store.state.kss.recurringSubtype;
        if (type === 'weekly' || (type === 'custom' && subType === 'week')) {
          const len = this.$store.state.kss.recurringDays
            .filter((e) => e.isSelected).length;

          if (len === 0) {
            this.$store.commit('setToastNotification', {
              id: Math.random(),
              message: this.$t('ToastComponenet.kss.selectRecurringDays'),
              type: 'error',
            });
            hasError = true;
          }
        }
      }

      return hasError;
    },
    checkDateAndTimeValid() {
      const selectedDate = new Date(this.$store.state.kss.selectedDate);
      const { selectedHours } = this.$store.state.kss;
      const { selectedMinutes } = this.$store.state.kss;
      selectedDate.setHours(selectedHours, selectedMinutes);

      const currentTime = new Date();
      const timeDifference = currentTime - selectedDate;
      return timeDifference <= 60 * 60 * 1000;
    },
    getRecurringObject(dateTaskBegin) {
      switch (this.$store.state.kss.recurringType) {
        case 'daily':
          return [{
            frequency: 'DAILY',
            start: dateTaskBegin,
            byDayOfWeek: ['MO', 'TU', 'WE', 'TH', 'FR'],
          }];
        case 'weekly':
          return [{
            frequency: 'WEEKLY',
            byDayOfWeek: this.$store.state.kss.recurringDays
              .filter((e) => e.isSelected)
              .map((e) => e.rSchName),
            start: dateTaskBegin,
          }];
        case 'monthly':
          return [{
            frequency: 'MONTHLY',
            start: dateTaskBegin,
          }];
        case 'custom':
          if (this.$store.state.kss.recurringSubtype === 'days') {
            return [{
              frequency: 'DAILY',
              interval: this.$store.state.kss.recurringEveryX,
              start: dateTaskBegin,
            }];
          }
          if (this.$store.state.kss.recurringSubtype === 'weeks') {
            return [{
              frequency: 'WEEKLY',
              interval: this.$store.state.kss.recurringEveryX,
              byDayOfWeek: this.$store.state.kss.recurringDays
                .filter((e) => e.isSelected)
                .map((e) => e.rSchName),
              start: dateTaskBegin,
            }];
          }
        // eslint-disable-next-line no-fallthrough
        default:
          return [];
      }
    },
    onToggleArchivedTasks() {
      this.displayArchivedTasks = !this.displayArchivedTasks;
      this.$emit('input', this.displayArchivedTasks);
    },
  },
  mounted() {
    this.$store.commit('setSelectedDate', new Date());
    this.$store.dispatch('getTeams');
    // pass the logged in user to select him by default
    this.$store.dispatch('getKssParticipants', this.$store.state.auth.user.name);
    this.$store.dispatch('getArchivedTasks');
    this.$store.dispatch('getCreatedTasks');
  },
  unmounted() {
    this.$store.commit('removeKssTeams');
    this.$store.commit('removeKssParticipants');
    this.$store.commit('removeArchivedTasks');
    this.$store.commit('removeCreatedTasks');
    this.$store.commit('resetInviteEmail');
  },
};
</script>

<style scoped>

.icon-and-text {
  margin: 30px;
  display: flex;
  align-items: center;
}

.users-icon {
  font-size: 28px;
  margin-right: 10px
}

.time-box {
  display: flex;
  flex-wrap: wrap;
}

.left-side {
  flex: 1;
}

.right-side {
  flex: 1;
}

.spacer {
  flex: 0.1;
}

.full-width {
  width: 100%;
  display: block;
  box-sizing: border-box;
}

.kss-icon {
  font-size: 30px;
  margin-right: 15px
}

.kss-text {
  font-family: "Poppins";
  font-size: 20px;
}

.info-text {
  font-family: "Poppins";
  font-size: 20px;
}

h1 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
  padding: var(--card-padding) 0 var(--card-padding);
}

h2 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

h3 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

h4 {
  padding: 0.5rem 0;
}

p {
  padding: 0.5rem 0;
}

.label-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.calendar-label, .clock-label {
  font-family: Poppins, sans-serif;
  margin-right: 30px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.calendar-icon, .clock-icon {
  font-size: 23px;
  margin-right: 10px;
}

/*---------------- CONTENT NEW ------------------*/
.box .content .forms {
  /* max-width: 90vw; */
  /* width: 600px; */
  /*height: auto;*/
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  background: var(--color-milk);
}

.box .content .forms .time-wrapper {
  /*min-height: 100vh;*/
  box-sizing: border-box;
  padding: 0px;
  max-width: 960px;
  margin: 0 auto;
}

.box .content .forms .time-wrapper .time-box {
  display: flex;
  flex-wrap: wrap;
}

.time-box .label {
  text-align: center;
  display: block;
  width: 350px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: var(--color-orange);
  background-color: var(--color-white);
  cursor: pointer;
}

.time-box .area {
  /*display: none;*/
  order: 99;
  flex-grow: 1;
  width: 100%;
}

.time-box input[type="checkbox"] {
  /*display: none;*/
}

.time-box input[type="checkbox"]:checked + label + .area {
  /*display: block;*/
}

/*---------------- CONTENT NEW: RUNTIME ------------------*/
.runtime {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--color-milk);
}

/*------------------ DROPDOWN -------------------*/
.box .content .forms .dropdown {
  width: 150px;
  height: 30px;
  display: none;
  /* position: relative; */
  /* display: flex; */
  /* float: right; */
  /* flex-direction: column; */
  margin-top: 30px;
}

select {
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-white);
  box-shadow: 5px 10px 8px var(--color-light-grey);
  cursor: pointer;
}

select option {
  width: 150px;
  height: 30px;
  font-size: 14px;
  padding: 10px;
  margin: 10px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-milk);
  cursor: pointer;
}

label {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 22px;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  margin: 2rem 0;
  box-sizing: border-box;
}

.btn-create {
  font-family: Poppins, sans-serif;
  margin-right: 10px;
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-create:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-delete {
  float: left;
  padding: 5px;
  font-size: 20px;
  width: 8rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background: transparent;
  cursor: pointer;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .time-box .label {
    margin-left: auto;
    margin-right: auto;
    width: 130px;
  }
  .time-box {
    flex-direction: column;
  }

  .time-box .label span {
    display: none;
  }

  .spacer {
    display: none;
  }

  .btns {
    flex-direction: column;
    align-items: flex-end;
  }

  .btn-create {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .icon-and-text {
    flex-direction: column;
    align-items: center;
  }

  .calendar-icon, .clock-icon {
    font-size: 20px;
    margin-right: 10px;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .time-box .label {
    margin-left: auto;
    margin-right: auto;
    width: 230px;
  }

  .time-box .label span {
    display: block;
  }

  .time-box {
    flex-direction: column;
  }

  .spacer {
    display: none;
  }

  .icon-and-text {
    flex-direction: column;
    align-items: center;
  }

  .calendar-icon, .clock-icon {
    font-size: 20px;
    margin-right: 10px;
  }

  .box .navlabel {
    padding: 16px 35px;
    margin: 16px 6px;
  }

  .box {
    padding: 0.75rem;
  }
}

@media (min-width: 1920px) {
  .time-box .label {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
