<template>
  <p id="question-text" class="question-text">
    {{ question.properties.content.text_question[getLocale] ?? '' }}
  </p>
  <div class="text-container">
    <textarea v-bind="answer" :placeholder="$t('TextQuestion.placeholder')" id="answer-text"
    @keyup="handleText"></textarea>
    <!--vee-field type="text" name="answer" id="answer-text"/-->
  </div>
</template>

<script>
export default {
  name: 'TextQuestion',
  data() {
    return {
      question: this.$store.state.questionnaireManagement.selectedQuestion,
    };
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    answer: {
      get() {
        return this.$store.state.questionnaireManagement.answer;
      },
      set(value) {
        this.$store.commit('setAnswer', value);
      },
    },
  },
  methods: {
    handleText(value) {
      this.$store.commit('setAnswer', value.target.value);
    },
  },
};
</script>

<style scoped>
.question-text {
  text-align: center;
  padding: 2rem 0;
}

/*------------------ TEXT AREA -------------------*/
.text-container {
  width: 100%; /* Width of the outside container */
}

#answer-text {
  width: 100%; /* Width of the outside container */
  height: 20rem;
  padding: 10px 15px;
  font-size: 16px;
  background-color: rgb(0, 0, 0, 0);
  position: relative;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #777777;
  resize: none;
  font-family: "Poppins";
}

  /*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .question-text {
    padding: 1rem 0.2rem;
  }
}

@media (min-width: 1920px) {
  .question-text {
    padding: 1rem 2rem 0;
    font-size: 23px;
  }
}
</style>
