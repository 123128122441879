import { createStore } from 'vuex';
import auth from '@/store/modules/auth';
import content from '@/store/modules/content';
import kss from '@/store/modules/kss';
import teamManagement from '@/store/modules/teamManagement';
import userManagement from '@/store/modules/userManagement';
import questionnaireManagement from '@/store/modules/questionnaireManagement';
import dashboard from '@/store/modules/dashboard';
import managementDashboard from '@/store/modules/managementDashboard';
import mindset360 from '@/store/modules/mindset360';

export default createStore({
  modules: {
    auth,
    content,
    kss,
    teamManagement,
    questionnaireManagement,
    dashboard,
    managementDashboard,
    userManagement,
    mindset360,
  },
});
